<template>
  <b-card-actions
    ref="cardA"
    title="Group List"
    :action-collapse="false"
    action-refresh
    no-body
    @refresh="getGoogleAdsMockupGroupList"
  >
    <b-card class="mb-2" no-body>
      <!-- !! Table Header -->
      <div class="mx-1">
        <b-row>
          <b-col class="mt-1" order="3" cols="12" order-sm="1" sm="6" md="4" lg="4">
            <div class="search-filter">
              <HeroInputText
                id="filter"
                v-model="tableConfig.filter"
                class="filter-input"
                placeholder="Search"
                @input="debounce(getGoogleAdsMockupGroupList, 500)()"
              />
            </div>
          </b-col>
          <b-col class="mt-1" order="2" cols="6" sm="4" md="3" lg="2">
            <HeroTablePerPage
              v-model="tableConfig.perPage"
              :options="tableConfig.perPageOptions"
              @input="debounce(getGoogleAdsMockupGroupList)()"
            />
          </b-col>
          <b-col class="mt-1" order="2" cols="6" sm="2" offset-md="2" md="3" offset-lg="4" lg="2">
            <HeroButtonAction
              type="button"
              variant="primary"
              @click="$bvModal.show('google-ads-mockup-group-form')"
            >
              Add
            </HeroButtonAction>
          </b-col>
        </b-row>
      </div>

      <!-- !! Table -->
      <b-row class="my-1">
        <b-col cols="12">
          <b-table
            show-empty
            striped
            sticky-header="100%"
            :responsive="true"
            :items="items"
            :fields="tableConfig.fields"
            :per-page="0"
            :sort-by="tableConfig.sortBy"
            :sort-direction="tableConfig.sortDirection"
            :no-sort-reset="true"
            :no-local-sorting="true"
            @sort-changed="onTableSortChanged"
          >
            <template #cell(name)="{ item }">
              <router-link :to="`/google-ads-mockup-group/${item.id}`">
                {{ item.name }}
              </router-link>
            </template>
            <template #cell(action)="{ item: { id, name } }">
              <feather-icon
                icon="Edit2Icon"
                size="20"
                class="cursor-pointer"
                @click="group = { id, name }; $bvModal.show('google-ads-mockup-group-form')"
              />
            </template>
          </b-table>
        </b-col>
      </b-row>

      <!-- !! Table Footer -->
      <div class="mx-1 mb-1">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <HeroTablePagination
              v-model="tableConfig.currentPage"
              :per-page="tableConfig.perPage"
              :total-rows="tableConfig.totalRows"
              @input="debounce(getGoogleAdsMockupGroupList)()"
            />
          </b-col>
          <b-col cols="12" sm="6" class="d-flex align-items-top justify-content-center justify-content-sm-end">
            <HeroTableStatus :per-page="tableConfig.perPage" :total-rows="tableConfig.totalRows" />
          </b-col>
        </b-row>
      </div>
    </b-card>
    <GoogleAdsMockupGroupFormModal
      :group="group"
      @hidden="group = { id: '', name: '' }"
      @form-success="getGoogleAdsMockupGroupList"
    />
  </b-card-actions>
</template>

<script>
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroTableStatus from '@/views/components/table/HeroTableStatus.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import HeroTablePerPage from '@/views/components/table/HeroTablePerPage.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import HeroTablePagination from '@/views/components/table/HeroTablePagination.vue'
import DebounceMixin from '@/mixins/debounce'
import moment from 'moment/moment'
import GoogleAdsMockupGroupFormModal from '@/views/google-ads-mockup/components/GoogleAdsMockupGroupFormModal.vue'
import SweetAlert from '@/services/SweetAlert'
import ErrorService from '@/services/ErrorService'
import axiosInstance from '@/libs/axiosInstance'

export default {
  name: 'GoogleAdsMockupGroup',
  components: {
    GoogleAdsMockupGroupFormModal,
    HeroTablePagination,
    BCardActions,
    HeroTablePerPage,
    HeroButtonAction,
    HeroTableStatus,
    HeroInputText,
  },
  mixins: [DebounceMixin],
  data() {
    return {
      group: { id: '', name: '' },
      items: [],
      tableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [5, 10, 25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'name',
        sortDirection: 'asc',
        timeInterval: moment(),
        fields: [
          {
            label: 'Group Name',
            key: 'name',
            sortable: true,
          },
          {
            label: 'Number Of Mockups',
            key: 'mockup_count',
            sortable: true,
          },
          {
            label: 'Action',
            key: 'action',
            sortable: false,
          },
        ],
      },
    }
  },
  mounted() {
    this.getGoogleAdsMockupGroupList()
  },
  methods: {
    onTableSortChanged(event) {
      this.tableConfig.sortBy = event.sortBy
      this.tableConfig.sortDirection = event.sortDesc ? 'desc' : 'asc'
      this.getGoogleAdsMockupGroupList()
    },

    async getGoogleAdsMockupGroupList() {
      try {
        this.$refs.cardA.showLoading = true

        const {
          perPage, currentPage, filter, sortBy, sortDirection,
        } = this.tableConfig

        const url = `google-ads-mockups/groups?perPage=${perPage}&page=${currentPage}&q=${filter}&orderByColumn=${sortBy}&orderByDirection=${sortDirection}`
        const response = await axiosInstance.get(url)

        this.tableConfig.currentPage = +response.data.data.current_page
        this.tableConfig.perPage = +response.data.data.per_page
        this.tableConfig.totalRows = +response.data.data.total
        this.items = response.data.data.data
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.$refs.cardA.showLoading = false
      }
    },
  },
}
</script>

<style scoped lang="scss">

</style>
